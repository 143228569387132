import {
  useMutation,
  useQueryClient,
  useQuery,
  useInfiniteQuery
} from "@tanstack/react-query"

import { DEFAULT_STALE_TIMES } from "shared/config"
import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"
import useExportHandler from "hooks/useExportHandler"

/**
 * @param {object} params
 * @param {number[]} params.ids
 * @param {boolean} params.web_shop
 * @param {{[key: number]: number[]}} params.add_on_ids
 * @param {{ [k: number]: { value: number } }} [params.quantities]
 * @param {boolean} params.affiliate_products
 * @param {object} [params.discounts]
 * @param {number} [params.promotion_ids]
 * @param {boolean} [params.shop_partner_price]
 * @param {boolean} [params.with_internal_quota]
 * @param {object} params.additional_search
 * @param {boolean} [params.additional_search.with_agency_customer_packages]
 * @param {string} params.additional_search.affiliate_type
 * @param {number} params.additional_search.affiliate_id
 */
export const useWebshopOrderPrice = (params) => {
  return useQuery({
    queryKey: ["web_shop_prices", "get_package_price", params],
    queryFn: async () => {
      const res = await client.get("/web_shop_prices/get_package_price.json", {
        params
      })

      return res.data
    }
  })
}

export const useCreateWebshopOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ order }) => {
      const response = await client.post("/web_shop_orders.json", order)
      return response.data
    },
    onSuccess: (_response) => {
      queryClient.invalidateQueries({
        queryKey: ["orders"]
      })
      queryClient.invalidateQueries({
        queryKey: ["v2", "web_shops"]
      })
    }
  })
}

export const useCreateOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (order) => {
      const response = await client.post("/orders.json", { order })
      return response.data
    },
    onSuccess: (_response) => {
      queryClient.invalidateQueries({
        queryKey: ["orders"]
      })
      queryClient.invalidateQueries({
        queryKey: ["v2", "open-quotas"]
      })
    }
  })
}

export const useImportedVacancyOrders = (importedVacancyId, params) => {
  return useInfiniteQuery({
    queryKey: [
      "v2",
      "imported_vacancies",
      importedVacancyId,
      "orders",
      "index",
      params
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get(
        `/v2/imported_vacancies/${importedVacancyId}/orders.json`,
        {
          params: {
            ...params,
            page: pageParam
          }
        }
      )

      return res.data
    },
    enabled: !!importedVacancyId,
    getNextPageParam
  })
}

export const useOrderStatistics = (orderId) =>
  useQuery({
    queryKey: ["v2", "orders", orderId, "statistics", "show"],
    queryFn: async () => {
      const res = await client(`/v2/orders/${orderId}/statistics.json`)

      return res.data
    },
    enabled: !!orderId
  })

export const useAgencyWebShopOrders = (params) => {
  return useInfiniteQuery({
    queryKey: ["v2", "web_shops", "agencies", "orders", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/web_shops/agencies/orders.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useAgencyOrderFiles = (orderId) => {
  return useQuery({
    queryKey: [
      "v2",
      "web_shops",
      "orders",
      "files",
      "index",
      "agencies",
      orderId
    ],
    queryFn: async () => {
      const res = await client.get(
        `/v2/web_shops/agencies/orders/${orderId}/files.json`
      )

      return res.data
    },
    useErrorBoundary: true
  })
}

export const useOrderDetails = (orderId) => {
  return useQuery({
    queryKey: ["web_shop_orders", orderId, "order_details"],
    queryFn: async () => {
      const res = await client.get(
        `/web_shop_orders/${orderId}/order_details.json`
      )

      return res.data.order_details
    },
    enabled: !!orderId,
    useErrorBoundary: true
  })
}

export const useOrdersXlsExport = (params) => {
  const onSuccess = useExportHandler("/web_shop_orders", "xls", params)

  return useQuery({
    queryKey: ["/web_shop_orders", "index", params],
    queryFn: async () => {
      const response = await client.get("/web_shop_orders.json", { params })

      return response.data
    },
    enabled: false,
    onSuccess
  })
}

export const useOrdersSourceZipVersions = (orderId) => {
  return useQuery({
    queryKey: ["orders", orderId, "source_zip_versions"],
    queryFn: async () => {
      const res = await client.get(
        `/orders/${orderId}/source_zip_versions.json`
      )

      return res.data
    },
    enabled: !!orderId,
    useErrorBoundary: true
  })
}

export const useOrderSalesProductTextsVersions = (orderId) => {
  return useQuery({
    queryKey: ["order_sales_product_texts", orderId, "versions"],
    queryFn: async () => {
      const res = await client.get(
        `/order_sales_product_texts/${orderId}/versions.json`
      )

      return res.data
    },
    enabled: !!orderId,
    useErrorBoundary: true
  })
}

export const useEndCustomerWebShopOrders = (params) => {
  return useInfiniteQuery({
    queryKey: ["v2", "web_shops", "end_customers", "orders", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/web_shops/end_customers/orders.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useOrderFiles = (orderId) => {
  return useQuery({
    queryKey: ["v2", "orders", "files", "index", orderId],
    queryFn: async () => {
      const res = await client.get(`/v2/orders/${orderId}/files.json`)

      return res.data
    },
    useErrorBoundary: true
  })
}

export const useEndCustomerOrderFiles = (orderId) => {
  return useQuery({
    queryKey: [
      "v2",
      "web_shops",
      "orders",
      "files",
      "index",
      "end_customers",
      orderId
    ],
    queryFn: async () => {
      const res = await client.get(
        `/v2/web_shops/end_customers/orders/${orderId}/files.json`
      )

      return res.data
    },
    useErrorBoundary: true
  })
}

export const usePartnerWebShopOrders = (params) => {
  return useInfiniteQuery({
    queryKey: ["v2", "web_shops", "partners", "orders", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/web_shops/partners/orders.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const usePartnerOrderFiles = (orderId) => {
  return useQuery({
    queryKey: [
      "v2",
      "web_shops",
      "orders",
      "files",
      "index",
      "partners",
      orderId
    ],
    queryFn: async () => {
      const res = await client.get(
        `/v2/web_shops/partners/orders/${orderId}/files.json`
      )

      return res.data
    },
    useErrorBoundary: true
  })
}

export const useAgencyCustomerWebShopOrders = (params) => {
  return useInfiniteQuery({
    queryKey: [
      "v2",
      "web_shops",
      "agency_customers",
      "orders",
      "index",
      params
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get(
        "/v2/web_shops/agency_customers/orders.json",
        {
          params: { ...params, page: pageParam }
        }
      )

      return res.data
    },
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useAgencyCustomerOrderFiles = (orderId) => {
  return useQuery({
    queryKey: [
      "v2",
      "web_shops",
      "orders",
      "files",
      "index",
      "agency_customers",
      orderId
    ],
    queryFn: async () => {
      const res = await client.get(
        `/v2/web_shops/agency_customers/orders/${orderId}/files.json`
      )

      return res.data
    },
    useErrorBoundary: true
  })
}

export const useLatestOrders = (params) => {
  return useQuery({
    queryKey: ["statistics", "latest_orders", params],
    queryFn: async () => {
      const res = await client.get("/statistics/latest_orders.json", { params })

      return res.data
    },
    staleTime: DEFAULT_STALE_TIMES.statistics,
    useErrorBoundary: true
  })
}

export const useIndeedOrders = (params) => {
  return useInfiniteQuery({
    queryKey: ["v2", "indeed", "orders", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/indeed/orders.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    enabled: !!params.q?.jap_customer_id_eq && !!params.q?.filter_by_sql,
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useDuplicatedOrders = (params, config = {}) => {
  return useInfiniteQuery({
    queryKey: ["v2", "indeed", "duplicated_orders", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/indeed/duplicated_orders.json", {
        params: { ...params, page: pageParam }
      })
      return res.data
    },
    enabled: !!params.q?.source_id_eq,
    ...config,
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useIndeedDuplicationRuleOrdersCount = (id) => {
  return useQuery({
    queryKey: ["v2", "indeed", "duplication_rules", id, "orders", "index"],
    queryFn: async () => {
      const res = await client.get(
        `/v2/indeed/duplication_rules/${id}/orders.json`
      )

      return res.data
    },
    enabled: !!id,
    useErrorBoundary: true
  })
}

export const useUnpublishAds = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const response = await client.post(
        "/v2/indeed/duplicated_orders/unpublish.json",
        values
      )
      return response.data
    },
    onSuccess: (_response) => {
      queryClient.invalidateQueries({
        queryKey: ["v2", "indeed", "duplicated_orders", "index"]
      })
    }
  })
}
